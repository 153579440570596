$page-card-height: 300px;

.page-card {
  height: $page-card-height;
  flex: 0 1 18%;
  box-sizing: border-box;
  margin-right: 2%;
  margin-bottom: 30px;
  cursor: pointer;
  position: relative;

  &_cover {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &_info {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000a0;
    display: none;
    top: 0;
    left: 0;
    color: white;
    text-align: center;
    line-height: normal;
    flex-direction: column;
    justify-content: center;

    .page-card:hover & {
      display: flex;
    }
  }
}
