@import '~bootstrap/scss/functions';

// https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// Color system

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$header-height: 64px;
$footer-height: 70px;
$header-padding: 27px;

$more-icon-height: 32px;

$option-height: 50px;
$selected-option-color: #f1f1f1;
$dragged-over-option-color: #d3d3d3;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@mixin checkered-background {
  background-image: linear-gradient(45deg, $gray-300 25%, transparent 25%),
    linear-gradient(-45deg, $gray-300 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, $gray-300 75%),
    linear-gradient(-45deg, transparent 75%, $gray-300 75%);
  background-size: 1rem 1rem;
  background-position: 0 0, 0 0.5rem, 0.5rem -0.5rem, -0.5rem 0;
}

@font-face {
  font-family: ltromatic;
  src: url('./ltromatic.ttf');
}
